/*eslint no-undef: 0*/

exports.onRouteUpdate = ({ location }) => {
    $("#go-to-top").click(function() { scroll_to_top(this.event) })
    $(".email-link").click(function() { this.href = 'mailto:eglise.goshen@gmail.com' })

    initialise_document_ready_functions()
}

// https://www.gatsbyjs.org/docs/add-offline-support-with-a-service-worker/#what-is-a-service-worker
// See https://stackoverflow.com/questions/47971439/is-it-possible-to-do-push-notifications-with-gatbsy

exports.onServiceWorkerUpdateReady = () => {
    const answer = window.confirm(
        `Du nouveau contenu est disponible. ` +
          `Voulez-vous recharger pour utiliser la dernière version ?`
    )
    
    if (answer === true) {
        window.location.reload()
    }
}